<!--  -->
<template>
  <div>
    <div class="box">

      <div class="div-title div-margin1">
        <i></i>
        <p class="p1">低相似度回复配置</p>
        <p class="p2">当用户提问内容与预设的问答内容相似度低于</p>
        <div class="div-input">
          <input type="text" class="input1" v-model="confidenceMin" onkeyup="value=value.replace(/[^0-9]/g,'')" @blur="changeInput">
          <p>%</p>
        </div>

        <p class="p2">时，AI的回复方式：</p>
      </div>
      <div class="radio-div">
        <el-radio-group v-model="replyTypeMin">

          <el-radio :label="1" class="fangdaaction">AI自动生成答案</el-radio>
          <el-radio :label="3" class="fangdaaction">使用标记的核心问答切片</el-radio>
          <el-radio :label="2" class="fangdaaction">使用默认回复话术</el-radio>
        </el-radio-group>
        <p class="p2" v-if="replyTypeMin == 2">配置多条文案，机器人会随机回复</p>



      </div>
      <div class="div-qa" v-if="replyTypeMin == 3">
        <div class="qa-title">
          <p class="qa-titlep1">当前已标记的核心问答</p>
          <p class="qa-titlep2" @click="pushQa">前往标记核心问答 > </p>
        </div>

        <div class="list-div" v-if="coreList !== undefined && coreList != null && coreList.length > 0">
          <div class="list-item" v-for="(item, index) in coreList">
            <p class="ask-div">{{ item.question }}</p>
            <p class="ans-div">{{ item.answer }}</p>

          </div>

        </div>



      </div>


      <div class="div-addpz" v-if="replyTypeMin == 2">

        <div class="pz-input" v-for="(item, index) in pzList">
          <el-input type="text" placeholder="请输入回复内容" v-model="pzList[index]" maxlength="1000">
          </el-input>
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/pzdelete.png" class="pz-delete"
            @click="deletePz(index)">
        </div>

        <div class="btn-addpz" @click="addPz" v-if="pzList.length < 5">
          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/pzadd.png">
          <p>新增一项</p>

        </div>
      </div>



      <div class="div-title div-margin">
        <i></i>
        <p class="p1">高相似度回复配置</p>

        <p class="p2">当用户提问内容与预设的问答内容相似度高于</p>

        <div class="div-input">
          <input type="text" class="input1" v-model="confidenceMax" onkeyup="value=value.replace(/[^0-9]/g,'')" 
          @blur="changeInput2"
          >
          <p>%</p>
        </div>

        <p class="p2">时，AI的回复方式：</p>
      </div>

      <div class="radio-div">
        <el-radio-group v-model="replyTypeMax">

          <el-radio :label="1" class="fangdaaction">AI自动生成答案</el-radio>
          <el-radio :label="4" class="fangdaaction">使用问答切片直接回复</el-radio>

        </el-radio-group>

      </div>

      <div class="div-title div-margin">
        <i></i>
        <p class="p1">历史对话引用数控制</p>
        <p class="p2">AI会根据历史内容与用户保持对话主题，历史内容引用数量越大回答更准确，但回复速度会降低</p>
      </div>
      <div class="div-slider">
        <el-slider v-model="askCount" :max="100" :min="0" @input="getSlide"></el-slider>
      </div>


      <div class="div-title div-margin">
        <i></i>
        <p class="p1">为员工选择Ai接口</p>
        <p class="p2">不同的AI接口对语言的处理存在差异</p>
      </div>

      <div class="div-ai">
        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/openai.png" alt="chatgpt3.5"
          :class="{ 'select-img': modelType == 'chatgpt3.5' }" @click="selectAi(0)">
        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/azure.png" alt="azure"
          :class="{ 'select-img': modelType == 'azure' }" @click="selectAi(1)">

        <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/wxyy.png" alt="文心一言"
          :class="{ 'select-img': modelType == 'qianfan' }" @click="selectAi(2)">


          <img src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/aigpt4.png" alt="其他模型"
          :class="{ 'select-img': modelType == 'chatgpt4' }" @click="selectAi(3)">
      </div>

      <i class="line"></i>

      <div class="confim-btn">

        <el-button type="primary" :disabled="!(confidenceMin)" @click="advance" :loading="btnloading">保存内容</el-button>
      </div>


    </div>


    <PublishDialog ref="childShare"></PublishDialog>
  </div>
</template>

<script>
import common from "common/js/common";
import { findEmpByIdV2, updateHigherEmp } from "api/aiworker/aiworker.js";
import PublishDialog from "./cells/PublishDialog.vue";
var regexp = new RegExp(/^[3-7]\d{1}$|80$/);
export default {
  components: {

    PublishDialog

  },
  data () {
    return {
      confidenceMin: 80,
      confidenceMax:95,
      modelType: 'chatgpt3.5',
      askCount: 5,
      replyTypeMin: 0,
      replyTypeMax: 0,

      empId: null,
      coreList: [],
      replyList: [],
      pzList: [''],
      orgId: null,
      btnloading: false
    }
  },
  methods: {

    showDialog (parm, parm2) {

      this.$refs.childShare.showPopup1(parm, parm2);
    },
    getSlide () {
      if (this.askCount < 5) {
        this.$message('历史对话引用数量最低为5条');
        this.askCount = 5;
      }
    },
    pushQa () {
    
      window.open(this.$common.outAddress2() + "qa?eid=" + this.$Base64.encode(this.empId + "") , '_blank');

    },
    changeInput () {
      if (this.confidenceMin > 95 | this.confidenceMin < 5) { 
        this.$message({
            message: '低相似度值配置范围为5到95之间，请修改',
            type: 'error'
          });
      }
    },
    changeInput2 () {
      if (this.confidenceMax > 95 | this.confidenceMax < 5) { 
        this.$message({
            message: '高相似度值配置范围为5到95之间，请修改',
            type: 'error'
          });
      }
    },
    addPz () {

      this.pzList.push('');
    },
    deletePz (i) {
      this.pzList.splice(i, 1)
    },
    selectAi (index) {
      if (index == 0) {
        this.modelType = 'chatgpt3.5';
      } else if (index == 1) {
        this.modelType = 'azure';
      } else if (index == 2) {
        this.modelType = 'qianfan';
      } else if (index == 3) {
        this.modelType = 'chatgpt4';
      }

    },

    advance () {
      if (this.confidenceMin > 95 | this.confidenceMin < 5) { 
        this.$message({
            message: '低相似度值配置范围为5到95之间，请修改',
            type: 'error'
          });
        return;
      }
      if (this.confidenceMax > 95 | this.confidenceMax < 5) { 
        this.$message({
            message: '高相似度值配置范围为5到95之间，请修改',
            type: 'error'
          });
        return;
      }
      this.btnloading = true;
      let opo = {
        userId: this.$localstore.get("hdUserId"),
        id: this.empId,
        confidenceMin: this.confidenceMin,
        confidenceMax: this.confidenceMax,
        askCount: this.askCount,
        modelType: this.modelType,
        replyTypeMax: this.replyTypeMax,
        replyTypeMin: this.replyTypeMin,
      };
      this.replyList = [];
      for (let i = 0; i < this.pzList.length; i++) {
        if (!common.isStringEmpty(this.pzList[i])) {
          this.replyList.push(this.pzList[i]);
        }
      }
      opo.replyList = this.replyList;

      console.log(opo);
      updateHigherEmp(opo).then((res) => {
        console.log(res);
        this.btnloading = false;
        if (res.code == 200) {
    
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.showDialog(this.empId, null);

        } else {

          if (!common.isStringEmpty(res.message)) {
            this.$message(res.message);
          }
        }
      }).catch((err) => {
        this.btnloading = false;
        if (!common.isStringEmpty(JSON.stringify(err))) {
          this.$message(JSON.stringify(err.message));
        }
      });
    },

  },
  //生命周期 - 创建完成（访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted () {
   // this.empId = this.$route.query.eid;
    this.empId = this.$Base64.decode(this.$route.query.eid);
    findEmpByIdV2(this.empId).then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.confidenceMin = res.data.confidenceMin;
        this.confidenceMax = res.data.confidenceMax;
        this.askCount = res.data.askCount;
        this.replyTypeMin = res.data.replyTypeMin;
        this.replyTypeMax = res.data.replyTypeMax;
        this.modelType = res.data.modelType;
        this.coreList = res.data.coreList;
        this.replyList = res.data.replyList;
        if (res.data.replyList !== undefined && res.data.replyList != null && res.data.replyList.length > 0) {
          this.pzList = res.data.replyList;
        }
      } else {
        if (!common.isStringEmpty(res.message)) {
          this.$message(res.message);
        }

      }
    }).catch((err) => {

      if (!common.isStringEmpty(JSON.stringify(err))) {
        this.$message(JSON.stringify(err.message));
      }
    });
  }
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.box {

  display: flex;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;

}

.div-title {
  display: flex;
  align-items: center;
}

.div-margin1 {
  margin-top: 30px;
}

.div-margin {
  margin-top: 60px;
}

.div-title i {
  width: 3px;
  height: 20px;
  background: #226CFF;
}

.p1 {
  font-size: 22px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 22px;
  margin-left: 6px;
  margin-right: 6px;
}

.p2 {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
}

.div-input {
  display: flex;
  align-items: center;
  height: 28px;
  width: 59px;
  line-height: 28px;
  font-size: 14px;
  background-color: #F9FAFB;
  border-radius: 6px 6px 6px 6px;
  color: #000000;
  border: 1px solid #D7E4F3;
  margin-left: 6px;
  margin-right: 6px;
}

.input1 {
  width: 25px;
  outline: none;
  background: rgba(0, 0, 0, 0);
  margin-left: 8px;
}

.input1:focus {
  outline: 0;
}

.div-input p {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;

}

.radio-div {
  display: flex;
  height: 60px;
  align-items: center;
  margin-top: 15px;

}

.fangdaaction {}

.radio-div /deep/ .el-radio {
  margin-right: 25px;


}

.radio-div /deep/ .el-radio__inner {
  width: 16px;
  height: 16px;

}

.radio-div /deep/ .el-radio__label {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.6);
}

.radio-div /deep/ .el-radio__input.is-checked .el-radio__inner {
  background: #226CFF !important;

}

.radio-div /deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #000000;
}

.div-slider {
  margin-top: 20px;
}

.div-ai {
  display: flex;
  margin-top: 28px;
}

.div-ai img {
  width: 160px;
  height: 84px;
  margin-right: 14px;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #E7F0F5;
  cursor: pointer;
}

.select-img {
  border: 1px solid #226CFF !important;
}

.line {
  width: 100%;
  height: 1px;
  background: #E7F0F5;
  margin-top: 80px;
}

.confim-btn {
  align-self: flex-end;

  margin-top: 12px;
  margin-bottom: 12px;
}


/deep/ .el-button {
  width: 280px;
  height: 52px;
  background: #226CFF;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

/deep/.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {

  background-color: #a0cfff;
  border-color: #a0cfff;
}

.div-qa {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.qa-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.qa-titlep1 {
  flex: 1;
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 16px;
}

.qa-titlep2 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #63749B;
  line-height: 14px;
  align-self: flex-end;
}

.list-div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 5px 5px;
  width: 32.2%;
  height: 138px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px #F4F7F9;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #EAF1F5;
}

.ask-div {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 18px;
  margin-top: 14px;
  margin-left: 14px;
  margin-right: 14px;

  /*设置单行 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ans-div {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 18px;
  margin-top: 8px;
  margin-left: 14px;
  margin-right: 14px;
  /* 设置多行*/
  overflow: hidden;
  text-overflow: ellipsis;
  /* 超出部分省略号 */
  word-break: break-all;
  /* 设置省略字母数字 */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.div-addpz {

  width: 100%;
  display: flex;
  flex-direction: column;

}

.pz-input {
  display: flex;
  align-items: center;
  margin-top: 10px;

}

.pz-delete {
  width: 20px;
  height: 20px;

  margin-left: 8px;
}

.btn-addpz {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 48px;
  background: #EAF1F9;
  border-radius: 10px 10px 10px 10px;

  margin-top: 10px;


}

.btn-addpz img {
  width: 18px;
  height: 18px;
}

.btn-addpz p {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #226CFF;
  margin-left: 5px;
}

/**改变input里的字体颜色*/
.div-addpz /deep/ input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-size: 16px;
}

.div-addpz /deep/ .el-input__inner {
  height: 52px;
  line-height: 52px;
  font-size: 16px;
  background-color: #F9FAFB;
  border-radius: 10px 10px 10px 10px;
  color: #333333;
  border: 1px solid #D7E4F3;
}

.div-addpz /deep/ .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0;
}</style>