<!-- dialog -->
<template>
<div class="publish-div">
 
  <el-dialog :visible.sync="show" :show-close="false" :before-close="handleClose">
    <div class="div-dialog">
      <img class="close_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/close_dialog.png"  @click="backHome(1)">
      <img class="success_dialog" src="https://cdn.shhd.info/APP/sys_img/digitalEmployee/dialog_success1.png" >
      <p class="dialog_title">{{dtitle}}</p>
      <div class="dialog_btn1" @click="backHome(2)">立即体验</div>
      <div class="dialog_btn2" @click="jumbNext">
        <p class="btn2_p1">配置知识库</p>
        <p class="btn2_p2">为数字员工配置详细的prompt，会更加智能</p>
      </div>
    </div>

  </el-dialog>

</div>
</template>

<script>
export default {
data() {
return {
  show: false,
  overlay: false,
  empId: null,
  oid: null,
  dtitle:'数字员工发布成功！可以开始使用啦～'
}
  },
  methods: {
    showPopup1 (params,params2) {
      this.empId = params;
      if (params2 == null) { 
        this.dtitle = '高级配置设置成功！快去试试吧～';
      }
      this.oid = params2;
      this.show = true;

    },
    jumbNext () {
      this.show = false;
      this.$router.replace({
        path: '/pmt/fileup?eid=' + this.$Base64.encode(this.empId + "") + "&flag=1"
      })
    },

    backHome (index2) { 
      this.show = false;
      // window.location.href = "https://www.baidu.com/"
      //跳转首页 刷新

    
   
     
      
      console.log("11111111111");
      var reload = localStorage.getItem('reloadWorkbenches');
      localStorage.setItem('reloadWorkbenches', parseInt(reload) + 1);
      console.log("2222222222");
      if (index2 == 2) { 

        window.open(this.$common.outAddress() + 'conversation?' +
          this.$Base64.encodeURI('id=' + JSON.stringify([parseInt(this.empId + "")])));
      }
      window.close();
    },
    handleClose(done) {
        
      }
  },
//生命周期 - 创建完成（访问当前this实例）
created() {

},
//生命周期 - 挂载完成（访问DOM元素）
mounted() {

}
}
</script>
<style scoped>
/* @import url(); 引入css类 */
.div-dialog{
  display: flex;
  flex-direction: column;
}
.close_dialog{
  width: 36px;
height: 36px;
margin-top: 12px;
margin-right: 12px;
align-self: flex-end;
}
.success_dialog{
  width: 160px;
height: 160px;
margin-top: 8px;

align-self: center;
}
.dialog_title{
  font-size: 18px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
margin-top: 18px;

align-self: center;
color: #000000;
}
.dialog_btn1{
  width: 348px;
  align-self: center;
  height: 60px;
background: #226CFF;
border-radius: 10px 10px 10px 10px;
font-size: 16px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #FFFFFF;
line-height: 60px;
margin-top: 40px;
text-align: center;
}


.dialog_btn2{
  display: flex;
  width: 348px;
  align-self: center;
  height: 60px;
  background: #FFFFFF;
border-radius: 10px 10px 10px 10px;
border: 1px solid #D7E4F3;
margin-top: 18px;
flex-direction: column;
justify-content: center;
align-items: center;
}

.btn2_p1{
  font-size: 16px;
font-family: PingFang SC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 16px
}
.btn2_p2{
  font-size: 10px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: rgba(0, 0, 0, 0.4);
line-height: 10px;
margin-top: 6px;
}

.publish-div /deep/.el-dialog {

  width: 400px;
height: 461px;
background: linear-gradient(180deg, #EAF1FA 0%, #FFFFFF 100%);
border-radius: 14px 14px 14px 14px;
opacity: 1;
border: 1px solid #FFFFFF;
}
.publish-div /deep/.el-dialog__header {

  display: none;

}
.publish-div /deep/.el-dialog__body {

  padding:0;
}
.pop-content{
  display: flex;
}
</style>